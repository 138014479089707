<template>
  <div :id="project.id" class="portfolio-section">
    <div :id="`left-section-${index}`" class="portfolio-section-column-left">
      <div class="portfolio-section-title">{{ project.title }}</div>
      <div class="portfolio-section-description">{{ project.results }}</div>
      <a
        :data-mixpanel="'portfolio_cta_' + project.id"
        :href="project.url"
        target="_blank"
        class="portfolio-cta portfolio-cta-bottom-left btn-countable btn-outline btn-outline-red portfolio-cta-left"
      >
        SEE MORE
      </a>
    </div>

    <div :id="`right-section-${index}`" class="portfolio-section-column-right">
      <img
        class="portfolio-img"
        :src="image"
        :alt="`${project.title}'s Website`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    image: String
  },
  mounted() {
    this.checkIfEven(this.index) ? this.moveElement() : "";
  },
  methods: {
    moveElement() {
      const imgElement = document.getElementById(`left-section-${this.index}`);
      const descriptionElement = document.getElementById(
        `right-section-${this.index}`
      );
      imgElement.before(descriptionElement);
    },
    checkIfEven(index) {
      return index & 1 ? false : true;
    }
  }
};
</script>

<style></style>
