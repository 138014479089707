<template>
  <main class="portfolio" v-if="isPortfolioEmpty">
    <section class="hero">

      <div class="hero-border-left"></div>

      <div class="hero-block fade-in-bck">
        <div class="hero-title fade-in-bck">
          OUR WORK
          <span class="hero-title-bar fade-in-bck"></span>
        </div>

        <div class="hero-subtitle">
          Game changing web technology across diverse sectors, from medical to
          energy and real estate.
          <a
            data-mixpanel="portfolio_cta_get_free_consultation"
            href="mailto:info@countable.ca"
            class="main-portfolio-cta btn-countable btn-outline btn-outline-red"
          >LET'S TALK</a>
        </div>
      </div>

      <PortfolioProject
        v-for="(project, index) in allProjects"
        :key="project.id"
        :project="project"
        :index="index"
        :image="returnImage(validateImg(project.image))"
      />
    </section>
  </main>
  <!-- Show loading spinner if content is still loading -->
  <div v-else class="loading-section">
    <div class="loader-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import API from "@/api/utils";
import { mapState } from "vuex";
import PortfolioProject from "@/components/Portfolio/PortfolioProjects";

export default {
  components: {
    PortfolioProject
  },
  computed: {
    ...mapState({
      allProjects: state =>
        state.projectList.sort((a, b) => a.title.localeCompare(b.title))
    }),
    isPortfolioEmpty() {
      return this.allProjects.length !== 0;
    }
  },
  methods: {
    validateImg(img) {
      return img ? img.url : "";
    },
    returnImage(imgLoc) {
      const host = API.getBaseURL();
      return `${host}${imgLoc}`;
    }
  },
  created() {
    this.$store.dispatch("fetchProjectsAPI");
    //this.$store.dispatch("fetchProjectList");
  }
};
</script>

<style></style>
